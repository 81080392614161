import React from "react";

function About() {
  return (
    <section id="about" className="container my-5">
      <article>
        <div className="card mb-4 ">
          <div className="card-body">
            <h2 className="card-title">About me</h2>
            <p className="lead">
              I am a passionate and driven Senior Software Developer with over 9
              years of experience in designing and implementing
              high-performance, user-centric software solutions. Specializing in
              full-stack development, I have a profound expertise in JavaScript
              frameworks and Python. My career is marked by a consistent record
              of innovation and a commitment to excellence in every project.
            </p>
          </div>
        </div>

        <div className="card mb-4 ">
          <div className="card-body">
            <h2 className="card-title">Technical Skills</h2>
            <ul className="list-group">
              <li className="list-group-item">
                Languages: JavaScript (React, Angular), Python, Java, Typescript
              </li>
              <li className="list-group-item">Frameworks: Node.js, Django</li>
              <li className="list-group-item">
                Tools: Docker, Kubernetes, Jenkins, AWS, Git
              </li>
              <li className="list-group-item">
                Databases: MySQL, MongoDB, PostgreSQL
              </li>
            </ul>
          </div>
        </div>

        {/* ... */}

        <footer className="card mt-5 ">
          <div className="card-body">
            <h2 className="card-title">Contact Information</h2>
            <p className="mb-1">
              Email:{" "}
              <a href="mailto:rishabhkdayal@gmail.com" className="card-link ">
                rishabhkdayal@gmail.com
              </a>
            </p>
            <p className="mb-1">
              LinkedIn:{" "}
              <a
                href="https://www.linkedin.com/in/rishabhkavidayal/"
                className="card-link "
              >
                linkedin.com/in/rishabhkavidayal
              </a>
            </p>
            <p className="mb-1">
              GitHub:{" "}
              <a href="https://github.com/rkd1989" className="card-link ">
                github.com/rkd1989
              </a>
            </p>
          </div>
        </footer>
      </article>
    </section>
  );
}

export default About;
