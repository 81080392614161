import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import "./App.css"; // Assuming your CSS is in App.css
import MailSentSuccess from "./components/MailSentSuccess";
import Home from "./components/Home";

function App() {
  return (
    <Router>
      <div className="content">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/mail-sent" element={<MailSentSuccess />} />
          {/* Add a Route here for the home page or any other pages */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
