import React from 'react';

function Footer() {
    return (
        <footer className="bg-dark text-white text-center p-3">
            <p>Copyright © Rishabh Kavidayal 2024</p>
        </footer>
    );
}

export default Footer;
