import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function MailSentSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => clearTimeout(timer); // This will clear the timer when the component unmounts
  }, [navigate]);

  return (
    <section id="projects" className="container my-5">
      <h2>
        Hey there ! Thanks for reaching out , I will respond as soon as possible
        !
      </h2>
    </section>
  );
}

export default MailSentSuccess;
