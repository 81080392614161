import React from 'react';

function Projects() {
    return (
        <section id="projects" className="container my-5">
            <h2>Projects</h2>
            {/* Project items here */}
        </section>
    );
}

export default Projects;
